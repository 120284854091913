import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-365d9974"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "big-img-box"
};
const _hoisted_2 = {
  class: "index"
};
const _hoisted_3 = {
  key: 0,
  class: "tab"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "img-info-box"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "level"
};
const _hoisted_9 = {
  class: "name render-font-family"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return $options.showPreImg ? (_openBlock(), _createBlock(_component_van_swipe, {
    key: 0,
    class: "img-preview-box",
    loop: false,
    "show-indicators": false,
    "initial-swipe": $props.imgIndex,
    onClick: _cache[0] || (_cache[0] = $event => $options.showPreImg = false)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: index,
        class: "img-preview"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(index + 1) + " / " + _toDisplayString($props.list.length), 1), item.have_back_flag ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
          class: _normalizeClass(['tab-item', 'tab-item-first', {
            'active': !item.showBack
          }]),
          onClick: _withModifiers($event => item.showBack = false, ["stop"])
        }, "图 1", 10, _hoisted_4), _createElementVNode("div", {
          class: _normalizeClass(['tab-item', {
            'active': item.showBack
          }]),
          onClick: _withModifiers($event => item.showBack = true, ["stop"])
        }, "图 2", 10, _hoisted_5)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
          class: "big-img",
          src: item.showBack ? item.back_image_url : item.card_icon
        }, null, 8, _hoisted_7), _createElementVNode("div", _hoisted_8, _toDisplayString(item.level_name), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.card_name), 1)])])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["initial-swipe"])) : _createCommentVNode("", true);
}