import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-47f02697"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "luckycards-box"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "btns-box-cont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)]), _renderSlot(_ctx.$slots, "content", {}, undefined, true), _createElementVNode("div", {
    class: _normalizeClass(['btns-box', {
      'btns-box-gray': $props.type == 'gray'
    }])
  }, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "btns", {}, undefined, true)])], 2)]);
}