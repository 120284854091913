import MDRouter from "@/service/router";
import { markRaw } from 'vue';
import { env } from "md-base-tools/env";
import domain from '@/service/domain';
import { mapState } from "vuex";
import { getSchemeUrl } from "@/utils/toolsApi";
import Error from "@@/common/Error.vue";
import CompBox from '@@/luckycards/box.vue';
import CompCardImg from '@@/luckycards/cardImg.vue';
import CompBack from '@@/luckycards/popupBack.vue';
import CompEWM from '@@/luckycards/popupEWM.vue';
import CompPriviewImg from '@@/luckycards/previewImg.vue';
import { cardPrize, getMallOrderPayInfo } from '@/api/index';
// import lowerdev from '@/service/lowerdev'

import { GoBack } from "@/utils/tools";
// import { PAGInit } from 'libpag';
import parseAPNG from 'apng-js';
import { throttle } from "@/utils/tools";
import ClipboardJS from "clipboard";
import { showToast } from "vant";
export default {
  components: {
    Error,
    CompBox,
    CompCardImg,
    CompBack,
    CompEWM,
    CompPriviewImg
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      cards_id: '',
      // 抽卡机ID
      pay_id: '',
      order_id: '',
      card_pack_id: '',
      is_next: true,
      ifFirst: true,
      card_info: {},
      list: [],
      show: false,
      alreadyNum: 0,
      showError: false,
      showMessage: '',
      pagView1: null,
      pagView2: null,
      canvas1: null,
      canvas2: null,
      player1: null,
      player2: null,
      drawType: 1,
      loading: false,
      page: 0,
      page_size: 20,
      timer: null,
      total: 0,
      showEWM: false,
      // 二维码弹层
      showPreImg: false,
      // 查看大图
      imgIndex: 1,
      point: '',
      // 支付获取的积分
      shop_point: "",
      point_box_msg: "",
      cp_user_id: ''
    };
  },
  created() {
    if (env.isWxApp()) {
      let query = {
        ...this.$route.query
      };
      const path = this.$route.path;
      let t = new Date().getTime();
      query.t = t;
      query.darkmode = true;
      this.$router.replace({
        path,
        query
      });
    }
  },
  async mounted() {
    await this.$nextTick();
    this.initApng01();
    this.initApng02();
    if (this.$route.query.id) {
      this.cards_id = this.$route.query.id;
    }
    let pay_id = sessionStorage.getItem('pay_id') || this.$route.query.pay_id || '';
    if (pay_id && !this.order_id) {
      this.pay_id = pay_id;
      let num = sessionStorage.getItem(this.pay_id);
      if (num == 'open') {
        this.is_next = false;
      } else if (Number(num) > 0) {
        this.alreadyNum = num;
      }
      await this.getInfo();
      this.setShare();
      if (env.isWxApp()) {
        await this.$nextTick();
        // eslint-disable-next-line no-undef
        postWXMessage({
          title: this.card_info.extract_card_name,
          imageUrl: this.card_info.extract_card_icon,
          url: `${domain.wap}/luckycards/fastsell/detail/${this.cards_id}`
        });
      }
    }
  },
  unmounted() {
    sessionStorage.removeItem('pay_id');
  },
  methods: {
    async initApng01() {
      const canvas1 = document.getElementById('pag');
      const ctx = canvas1.getContext('2d');
      const img1Src = "https://p6.moimg.net/md/image/card_bg01.png";
      this.player1 = markRaw(await this.createApngPlayer1(img1Src, ctx));
    },
    async initApng02() {
      const canvas2 = document.getElementById('pag_bg');
      const ctx = canvas2.getContext('2d');
      const img2Src = "https://p6.moimg.net/md/image/card_01.png";
      this.player2 = markRaw(await this.createApngPlayer2(img2Src, ctx));
      this.player2.on('end', () => {
        setTimeout(() => {
          canvas2.style.display = 'none';
          this.onLoad();
        }, 200);
      });
    },
    async initPag() {
      const PAG = window.PAG;
      if (window.buffer1 && window.buffer2) {
        await Promise.all([await PAG.PAGFile.load(window.buffer1), await PAG.PAGFile.load(window.buffer2)]).then(async e => {
          let pagFile1 = e[0];
          let pagFile2 = e[1];
          const canvas1 = document.getElementById('pag');
          const canvas2 = document.getElementById('pag_bg');
          this.pagView1 = markRaw(await PAG.PAGView.init(pagFile1, canvas1, {
            useCanvas2D: true
          }));
          this.pagView2 = markRaw(await PAG.PAGView.init(pagFile2, canvas2, {
            useCanvas2D: true
          }));
          this.pagView1.setRepeatCount(1);
          this.pagView2.setRepeatCount(1);
          this.pagView2.setScaleMode(3);
          this.pagView1.addListener('onAnimationEnd', () => {
            canvas1.style.display = 'none';
          });
          this.pagView2.addListener('onAnimationEnd', () => {
            setTimeout(() => {
              canvas2.style.display = 'none';
              this.onLoad();
            }, 200);
          });
        });
      } else {
        window.buffer1 = await fetch('https://p6.moimg.net/md/image/card_bg01.pag').then(response => response.arrayBuffer());
        window.buffer2 = await fetch('https://p6.moimg.net/md/image/card_01.pag').then(response => response.arrayBuffer());
        this.initPag();
      }
    },
    playPag1() {
      const canvas1 = document.getElementById('pag');
      if (canvas1) {
        canvas1.style.display = 'block';
        this.pagView1.play();
      }
    },
    playPag2() {
      const canvas2 = document.getElementById('pag_bg');
      if (canvas2) {
        canvas2.style.display = 'block';
        this.pagView2.play();
      }
    },
    async getInfo() {
      const {
        status,
        data,
        message
      } = await getMallOrderPayInfo({
        pay_id: this.pay_id
      });
      if (status == 0 && data.pay_status == 2) {
        this.card_info = data.card_info;
        this.order_id = data.order_id;
        this.point = data.point;
      } else {
        // this.showError = true
        this.showMessage = message || '';
        this.$router.replace({
          path: `/luckycards/fastsell/detail/${this.cards_id}`
        });
      }
    },
    draw: throttle(async function (type) {
      if (this.loading) return;
      this.loading = true;
      this.drawType = type;
      await this.$nextTick();
      if (this.ifFirst && this.alreadyNum == 0 && this.is_next || this.list && this.list.length == 0) {
        const canvas1 = document.getElementById('pag');
        canvas1.style.display = 'block';
        this.player1.play();
      }
      const canvas2 = document.getElementById('pag_bg');
      canvas2.style.display = 'block';
      this.player2.play();
    }),
    drawFunc() {
      cardPrize({
        order_id: this.order_id,
        type: this.drawType,
        card_pack_id: this.card_pack_id,
        // 卡牌所属卡包id，首次开奖无需传值，继续拆包需要带上此参数【类似下一页】
        page: this.page,
        page_size: this.page_size
      }).then(({
        status,
        data,
        message
      }) => {
        this.loading = false;
        if (status == 0) {
          this.total = data.total;
          this.ifFirst = false;
          if (this.page == 1) {
            this.list.splice(0, this.list.length);
          }
          this.list.push(...data.list);
          this.card_pack_id = data.card_pack_id;
          this.shop_point = data.shop_point, this.point_box_msg = data.point_box_msg, this.cp_user_id = data.cp_user_id, this.is_next = data.is_next;
          this.loading = false;
        } else {
          showToast(message);
        }
        if (this.is_next) {
          let num = Number(sessionStorage.getItem(this.pay_id)) || 0;
          sessionStorage.setItem(this.pay_id, num += 1);
        } else {
          sessionStorage.setItem(this.pay_id, 'open');
        }
      });
    },
    onLoad() {
      this.loading = true;
      if (!this.is_next) {
        this.loading = false;
        return false;
      }
      if (this.page == 0 || this.loading && this.page <= this.total) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.page++;
          console.log(this.page);
          this.drawFunc();
        }, 100);
      } else {
        this.loading = false;
      }
    },
    // 继续抽卡
    goCardsDetail() {
      this.$router.replace(`/luckycards/fastsell/detail/${this.card_info.extract_card_id}`);
    },
    goBack() {
      GoBack();
    },
    checkoutAll() {
      sessionStorage.setItem(this.pay_id, 'open');
      this.$router.replace(`/luckycards/fastsell/detail/${this.cards_id}`);
    },
    goUserCollect() {
      this.$router.push({
        path: '/luckycards/fastsell/user_collect',
        query: {
          id: this.cards_id
        }
      });
    },
    goContainer() {
      const url = `${domain.wap}/luckycards/container`;
      MDRouter.Link(url);
    },
    // 分享
    setShare() {
      let url = `${domain.wap}/luckycards/fastsell/detail/${this.card_info.extract_card_id}?utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.card_info.extract_card_icon,
        msg_des: `${this.card_info.extract_card_name} | 摩点 ${url}`,
        qq_des: this.card_info.extract_card_name,
        qq_share_url: `${url}`,
        qq_title: this.card_info.extract_card_name,
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `${this.card_info.extract_card_name}${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: "",
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: this.card_info.extract_card_name,
        wx_title: "",
        mini_programs_title: `${this.card_info.extract_card_name} | 摩点`,
        mina_share_img: this.card_info.extract_card_icon,
        mina_app_name: 'gh_7210ac3f1f08',
        mina_share_path: '/pages/index/index?nextUrl=pages/luckycards/detail/detail&id=' + this.card_info.extract_card_id
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    onShare() {
      if (!env.isWxApp()) {
        //     // eslint-disable-next-line no-undef
        //     wx.miniProgram.postMessage({ 
        //         data: {
        //             title: this.card_info.extract_card_name,
        //             imageUrl: this.card_info.extract_card_icon
        //         }
        //      })
        // } else {
        // eslint-disable-next-line no-undef
        userHeader.shareShow();
      }
    },
    async createApngPlayer1(url, ctx, options = {}) {
      const canvas1 = document.getElementById('pag');
      const imgBuffer = await this.getImgBuffer(url);
      const apng = parseAPNG(imgBuffer);
      Object.keys(options).forEach(key => {
        apng[key] = options[key];
      });
      canvas1.width = apng.width;
      canvas1.height = apng.height;
      apng.numPlays = 1;
      const player = await apng.getPlayer(ctx);
      return player;
    },
    async createApngPlayer2(url, ctx, options = {}) {
      const canvas2 = document.getElementById('pag_bg');
      const imgBuffer = await this.getImgBuffer(url);
      const apng = parseAPNG(imgBuffer);
      Object.keys(options).forEach(key => {
        apng[key] = options[key];
      });
      canvas2.width = apng.width;
      canvas2.height = apng.height;
      apng.numPlays = 1;
      const player = await apng.getPlayer(ctx);
      return player;
    },
    async getImgBuffer(url) {
      const blob = await fetch(url).then(res => res.blob());
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      const res = await new Promise(resolve => {
        reader.onload = () => {
          resolve(reader.result);
        };
      });
      return res;
    },
    // 展示二维码弹层
    showJoin() {
      this.showEWM = true;
    },
    // 查看大图
    showImgPreview(index) {
      this.showPreImg = true;
      this.imgIndex = index;
    },
    // 查看积分
    goScore() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: "md://user_score",
        webFun: () => {
          // eslint-disable-next-line no-undef
          userHeader.getMdlink({
            type: "user_score"
          });
          // eslint-disable-next-line no-undef
          location.href = userHeader.mdlink;
        }
      });
    },
    // 积分宝箱
    async goTreasure(e) {
      if (this.cp_user_id) {
        let url = '';
        const VUE_env = process.env.VUE_APP_ENV;
        if (VUE_env && VUE_env != 'pre') {
          url = `md://mini_program?id=wx3b0aeea5ba10856c&name=gh_5638b10d3ccb&path=pages/treasurePages/treasure_detail/treasure_detail?cp_user_id=${this.cp_user_id}`;
        } else {
          url = `md://mini_program?id=wx6a3c68659310e879&name=gh_7210ac3f1f08&path=pages/treasurePages/treasure_detail/treasure_detail?cp_user_id=${this.cp_user_id}`;
        }
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "openController",
          functionParams: url,
          webFun: async () => {
            const param = {
              path: "/pages/index/index",
              query: `nextUrl=pages/treasurePages/treasure_detail/treasure_detail&cp_user_id=${this.cp_user_id}`
            };
            const option = {
              jump_wxa: JSON.stringify(param)
            };
            const url = await getSchemeUrl(option);
            if (url) {
              if (env.isWeibo()) {
                const clipboard = new ClipboardJS(e.target, {
                  text: () => url
                });
                clipboard.on('success', () => {
                  showToast('内容已复制，请前往手机浏览器查看');
                  // 释放内存
                  clipboard.off('error');
                  clipboard.off('success');
                  clipboard.destroy();
                });
                clipboard.on('error', () => {
                  // 不支持复制
                  showToast({
                    type: 'waning',
                    message: '该浏览器不支持自动复制'
                  });
                  // 释放内存
                  clipboard.off('error');
                  clipboard.off('success');
                  clipboard.destroy();
                });
                clipboard.onClick(e);
              } else {
                location.href = url;
              }
            }
          }
        });
      }
    }
  }
};