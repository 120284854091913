export default {
  props: ['show'],
  computed: {
    showBack: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  },
  methods: {
    goBack() {
      this.$emit('checkoutAll');
      // this.$router.go(-1)
    }
  }
};