export default {
  props: ['show', 'list', 'imgIndex'],
  computed: {
    showPreImg: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  },
  data() {
    return {
      // show: true
    };
  }
};